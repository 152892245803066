import { Icon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { BsPlusCircle } from "react-icons/bs";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as api from "../API/staysApi";
import * as catApi from "../API/categoryApi";
import * as amenApi from "../API/amenitiesApi";
import * as bedApi from "../API/bedApi";
import * as countryApi from "../API/countryApi";
import * as bathApi from "../API/bathApi";
import * as highApi from "../API/highlightApi";
import * as citiesApi from "../API/citiesApi";
import * as currenciesApi from "../API/currencyApi";
import * as cancelApi from "../API/cancelationApi";
import * as areaApi from "../API/areaApi";
import * as ruleApi from "../API/rulesApi";
import { useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { FiCalendar, FiEdit } from "react-icons/fi";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import Toolbar from "react-multi-date-picker/plugins/toolbar";
import AddStayDetails from "./AddStayDetails";
import dayjs from "dayjs";

function AddModal({ Edit, Id }) {
  const [id, setId] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [numberOfBathRooms, setNumberOfBathRooms] = useState(1);
  const [countryId, setCountryId] = useState("");
  const [cityId, setCityId] = useState("");
  const [areaId, setAreaId] = useState("");
  const [name, setName] = useState();
  const [desc, setDesc] = useState();
  const [subTitle, setSubTitle] = useState();
  const [internalName, setInternalName] = useState();
  const [categoryId, setCategoryId] = useState();
  const [amenitiesId, setAmenitiesId] = useState([]);
  const [image360url, setImage360url] = useState();
  const [policyId, setPolicyId] = useState();
  const [checkInTime, setCheckInTime] = useState();
  const [checkOutTime, setCheckOutTime] = useState();
  const [numberOfBedrooms, setNumberOfBedrooms] = useState(1);
  const [highlightsId, setHighlightsId] = useState([]);
  const [rulesId, setRulesId] = useState([]);
  const [dPrice, setDPrice] = useState();
  const [wPrice, setWPrice] = useState();
  const [sale, setSale] = useState({ salePercent: 0, onSale: false });
  const [isNew, setIsNew] = useState(false);
  const [amenitiesHighlight, setAmenitiesHighlights] = useState();
  const [address, setAddress] = useState({
    street: "",
    floor: "",
    building: "",
  });
  const [price, setPrice] = useState({
    cleanFeas: "",
    currency: "62a8c909d04dc9682bdb2a9b",
  });
  const [numberOfGuests, setNumberOfGuests] = useState({
    minimum: "",
    maximum: "",
  });
  const [locationUrl, setLocationUrl] = useState();
  const [location, setLocation] = useState({ lat: "", long: "" });
  const [additionalRules, setAdditionalRules] = useState();
  const [importantInfo, setImportantInfo] = useState(1);
  const [transPortInfo, setTransPortInfo] = useState(1);
  const [otherDistance, setOtherDistance] = useState(1);
  const [sizeInM, setSizeInM] = useState();
  const [weeklyDiscount, setWeeklyDiscount] = useState();
  const [monthlyDiscount, setMonthlyDiscount] = useState();
  const [numberOfDateRate, setNumberOfDateRate] = useState(1);
  const [rateAccordingToDate, setRateAccordingToDate] = useState([]);
  const [distanceFromDownTown, setDistanceFromDownTown] = useState();
  const [minNbOfNights, setMinNbOfNights] = useState();
  const [maxNbOfNights, setMaxNbOfNights] = useState();
  const queryClient = useQueryClient();
  const { data: categories } = useQuery(
    "categories",
    catApi.getStaysCategories
  );
  const { data: amenities } = useQuery("amenities", amenApi.getAmenities);
  const { data: beds } = useQuery("beds", bedApi.getBedrooms);
  const { data: countries } = useQuery("countries", countryApi.getCountries);
  const { data: baths } = useQuery("baths", bathApi.getBathrooms);
  const { data: highlights } = useQuery("highlights", highApi.getHighlights);
  const { data: rules } = useQuery("rules", ruleApi.getRules);
  const { data: currencies } = useQuery(
    "currencies",
    currenciesApi.getCurrencies
  );
  const { data: cancelations } = useQuery(
    "cancelations",
    cancelApi.getCancelations
  );

  function handleChange(e, i, type) {
    setRateAccordingToDate({
      ...rateAccordingToDate,
      [i]: dayjs(e).format("YYYY-MM-DD") + "T00:00:00.000Z",
    });
  }
  //   Get Term Data for Edit

  const { data: stay } = useQuery(
    ["Stay", id],
    () => api.getStay(id).finally(() => {}),
    {
      enabled: Boolean(id),
    }
  );

  // GET CITY BY COUNTRY ID
  const { data: countryCities } = useQuery(
    ["Citystay", countryId],
    () => citiesApi.getCitiesByCountryId(countryId).finally(() => {}),
    {
      enabled: Boolean(countryId),
    }
  );
  // GET Area BY COUNTRY ID
  const { data: countryAreas } = useQuery(
    ["AreaStay", countryId],
    () => areaApi.getAreasByCountryId(countryId).finally(() => {}),
    {
      enabled: Boolean(countryId),
    }
  );

  const { mutate: postMutate } = useMutation([], api.addStay, {
    onSuccess: () => {
      queryClient.invalidateQueries(["Stays"]);
      toast({
        title: "Added successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      onClose();
    },
    onError: (error) => {
      toast({
        title: error.response.data.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
  });

  function handleRules(id) {
    if (rulesId?.indexOf(id) > -1) {
      setRulesId(rulesId.filter((hId) => hId !== id));
    } else {
      if (rulesId?.length < 1) {
        setRulesId([id]);
      } else {
        setRulesId([...rulesId, id]);
      }
    }
  }

  function handleHighlights(id) {
    if (highlightsId?.indexOf(id) > -1) {
      setHighlightsId(highlightsId.filter((hId) => hId !== id));
    } else {
      if (highlightsId?.length < 1) {
        setHighlightsId([id]);
      } else {
        setHighlightsId([...highlightsId, id]);
      }
    }
  }
  function handleAmenities(id) {
    if (amenitiesId?.indexOf(id) > -1) {
      setAmenitiesId(amenitiesId.filter((hId) => hId !== id));
    } else {
      if (amenitiesId?.length < 1) {
        setAmenitiesId([id]);
      } else {
        setAmenitiesId([...amenitiesId, id]);
      }
    }
  }

  function handleAddingStay() {
    let allDates = [];
    // let dateLength = Object.keys(rateAccordingToDate).length;
    [...Array(numberOfDateRate)].map((_, i) => {
      allDates[i] = rateAccordingToDate[i];
    });
    let rateMonth = [];
    [...Array(numberOfDateRate)].map((_, i) => {
      rateMonth[i] = {
        price: document.getElementById("rate" + i).value,
        dates: allDates[i],
      };
    });

    let bedrooms = [],
      bedTypes = [],
      bathrooms = [],
      bathTypes,
      transportInfo = [],
      otherDistance = [],
      importantInfo = [];

    // Adding Bed Rooms
    [...Array(transPortInfo)].map((_, i) => {
      transportInfo[i] = document.getElementById(`transport${i}`)?.value;
    });
    [...Array(otherDistance)].map((_, i) => {
      otherDistance[i] = document.getElementById(`otherDistance${i}`)?.value;
    });
    [...Array(importantInfo)].map((_, i) => {
      importantInfo[i] = document.getElementById(`importantInfo${i}`)?.value;
    });

    // Adding Bed Rooms
    [...Array(numberOfBedrooms)].map((_, i) => {
      beds.map((b, idx) => {
        if (document.getElementById(`chbx${i}-${idx}`).checked) {
          bedTypes[idx] = document.getElementById(`chbx${i}-${idx}`)?.value;
        }
      });
      bedrooms[i] = {
        name: document.getElementById(`name${i}`).value,
        beds: bedTypes,
      };
    });
    // Adding Bath Rooms
    [...Array(numberOfBathRooms)].map((_, idx) => {
      const rdarr = document.getElementsByName(`radioName${idx}`);
      for (var i = 0; i < rdarr.length; i++) {
        if (rdarr[i].checked) {
          bathTypes = rdarr[i].value;
        }
      }

      bathrooms[idx] = {
        name: document.getElementById(`bathName${idx}`).value,
        bathType: bathTypes,
      };
    });

    const body = {
      name,
      briefDescription: desc,
      subtitle: subTitle,
      internalName,
      category: categoryId,
      sizeInM: sizeInM,
      amenities: amenitiesId,
      distanceFromDowntown: distanceFromDownTown,
      checkInTime: checkInTime,
      checkOutTime: checkOutTime,
      numberOfBedrooms: numberOfBedrooms,
      bedrooms,
      numberOfBathrooms: numberOfBathRooms,
      bathrooms: bathrooms,
      highlights: highlightsId,
      weeklyDiscount: weeklyDiscount / 100,
      monthlyDiscount: monthlyDiscount / 100,
      rules: rulesId,
      country: countryId,
      additionalRules,
      city: cityId,
      area: areaId,
      address,
      price,
      amenitiesHighlight,
      numberOfGuests,
      minNbOfNights,
      maxNbOfNights,
      image360Urls: image360url,
      sale: {
        salePercent: sale.salePercent / 100,
        onSale: sale.onSale,
      },
      new: isNew,
      locationUrl: locationUrl,
      location,
      cancelationPolicy: policyId,
      priceAccordingToDate: rateMonth,
      priceAccordingToWeekDays: { price: dPrice },
      priceAccordingToWeekends: { price: wPrice },
      transPortInfo: transportInfo,
      otherDistance,
      importantInfo,
    };

    postMutate(body);
  }

  function handleEdit() {
    onOpen();
    setId(Id);
  }
  return (
    <>
      {Edit === true ? (
        <Icon
          onClick={() => handleEdit()}
          as={FiEdit}
          ml={4}
          size={6}
          fontSize={"bold"}
        />
      ) : (
        <Button onClick={onOpen} bg={"#FED438"}>
          Add Stay
          <Icon as={BsPlusCircle} ml={4} size={5} fontSize={"bold"} />
        </Button>
      )}
      <Modal size={"full"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <HStack spacing={"5"}>
              <Text>{Edit ? "Edit Stay" : "Add Stay"}</Text>
              <AddStayDetails />
            </HStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Tabs>
              <TabList>
                <Tab>Basic Info</Tab>
                <Tab>Details</Tab>
                <Tab>Beds & Baths</Tab>
                <Tab>Address</Tab>
                <Tab>Pricing</Tab>
                <Tab>Rules</Tab>
                <Tab>Others</Tab>
              </TabList>

              <TabPanels>
                {/* BASIC INFO */}
                <TabPanel>
                  {/* Info */}
                  <HStack
                    alignItems={"start"}
                    justifyContent={"start"}
                    spacing={"10px"}
                  >
                    <Input
                      placeholder="StayName:"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <Input
                      placeholder="internal Name:"
                      value={internalName}
                      onChange={(e) => setInternalName(e.target.value)}
                    />
                  </HStack>
                  <Textarea
                    mt={"5"}
                    h={"150px"}
                    placeholder="brief Description :"
                    value={desc}
                    onChange={(e) => setDesc(e.target.value)}
                  ></Textarea>
                  {/* <Input placeholder="Subtitle :" value={subTitle === undefined ? stay?.subtitle : subTitle} onChange={(e) => setSuTitle(e.target.value)} /> */}
                </TabPanel>
                {/* DETAILS */}
                <TabPanel>
                  <Box>
                    <Text my={5}>Number of guests:</Text>
                    <HStack spacing={5}>
                      <Box>
                        <Text my={2}>Minimum:</Text>
                        <Input
                          type="number"
                          placeholder="minimum"
                          onChange={(e) =>
                            setNumberOfGuests({
                              ...numberOfGuests,
                              minimum: parseInt(e.target.value),
                            })
                          }
                        />
                      </Box>
                      <Box>
                        <Text my={2}>Maximum:</Text>
                        <Input
                          type="number"
                          placeholder="Maximum"
                          onChange={(e) =>
                            setNumberOfGuests({
                              ...numberOfGuests,
                              maximum: parseInt(e.target.value),
                            })
                          }
                        />
                      </Box>
                    </HStack>
                  </Box>
                  {/* Category */}
                  <Box>
                    <Text my={5}>Category</Text>
                    <Select
                      onChange={(e) => setCategoryId(e.target.value)}
                      w={"20%"}
                    >
                      <option>Select Category</option>
                      {categories?.map((cat) => (
                        <option key={cat._id} value={cat._id}>
                          {cat.name}
                        </option>
                      ))}
                    </Select>
                  </Box>
                  <Box my={10} w={"40%"}>
                    <Text my={2}>Amenities Highlight:</Text>
                    <Input
                      type="text"
                      placeholder="Amenities Highlights"
                      onChange={(e) => setAmenitiesHighlights(e.target.value)}
                    />
                  </Box>
                  {/* Amenities */}
                  <Box>
                    <Text my={5}>Amenities</Text>
                    <Flex flexWrap={"wrap"}>
                      {amenities?.map((am, idx) => (
                        <Checkbox
                          my={"2"}
                          onChange={() => handleAmenities(am._id)}
                          px={2}
                          key={am._id}
                          colorScheme={"green"}
                        >
                          {am.name}
                        </Checkbox>
                      ))}
                    </Flex>
                  </Box>
                  {/* Highlights */}
                  <Box>
                    <Text my={5}>Highlights:</Text>
                    <Flex>
                      {highlights?.map((h) => (
                        <Checkbox
                          onChange={() => handleHighlights(h._id)}
                          px={2}
                          key={h._id}
                          colorScheme={"green"}
                        >
                          {h.name}
                        </Checkbox>
                      ))}
                    </Flex>
                  </Box>
                  {/* Size in M */}
                  <Box>
                    <Text my={5}>Size in M:</Text>
                    <Flex>
                      <Input
                        w="20%"
                        placeholder="Size in M"
                        type="number"
                        value={sizeInM}
                        onChange={(e) => setSizeInM(e.target.value)}
                      />
                    </Flex>
                  </Box>
                </TabPanel>

                {/* Beds & Baths */}
                <TabPanel>
                  {/* Beds */}
                  <Box>
                    {[...Array(numberOfBedrooms)].map((_, i) => (
                      <Box key={`bedroom${i}`}>
                        <Text my={5}>BedRooms:</Text>
                        <Flex>
                          <Input
                            w={"25%"}
                            mr={"5"}
                            id={`name${i}`}
                            placeholder="Room Name"
                          />
                          <Box>
                            <Button
                              mr={2}
                              colorScheme={"green"}
                              onClick={() =>
                                setNumberOfBedrooms((prev) => prev + 1)
                              }
                            >
                              <Icon as={BsPlusCircle} />
                            </Button>
                            <Button
                              onClick={() =>
                                setNumberOfBedrooms(numberOfBedrooms - 1)
                              }
                              display={numberOfBedrooms === 1 ? "none" : ""}
                              colorScheme={"red"}
                            >
                              <Icon as={AiOutlineDelete} />
                            </Button>
                          </Box>
                        </Flex>
                        <Flex mt={5} alignItems={"center"}>
                          {beds?.map((b, idx) => (
                            <Checkbox
                              w={"25%"}
                              id={`chbx${i}-${idx}`}
                              value={b._id}
                              key={b._id}
                              px={2}
                              colorScheme={"green"}
                            >
                              {b.name}
                            </Checkbox>
                          ))}
                        </Flex>
                      </Box>
                    ))}
                  </Box>
                  {/* BathRooms */}
                  <Box>
                    {[...Array(numberOfBathRooms)].map((_, i) => (
                      <Box key={`bathroom${i}`}>
                        <Text my={5}>BathRooms:</Text>
                        <Flex>
                          <Input
                            w={"25%"}
                            mr={"5"}
                            id={`bathName${i}`}
                            placeholder="Bath Name"
                          />
                          <Box>
                            <Button
                              mr={2}
                              colorScheme={"green"}
                              onClick={() =>
                                setNumberOfBathRooms((prev) => prev + 1)
                              }
                            >
                              <Icon as={BsPlusCircle} />
                            </Button>
                            <Button
                              onClick={() =>
                                setNumberOfBathRooms(numberOfBathRooms - 1)
                              }
                              display={numberOfBathRooms === 1 ? "none" : ""}
                              colorScheme={"red"}
                            >
                              <Icon as={AiOutlineDelete} />
                            </Button>
                          </Box>
                        </Flex>
                        <Flex mt={5} alignItems={"center"}>
                          <RadioGroup name={`radioName${i}`}>
                            {baths?.map((b, idx) => (
                              <Radio
                                id={`rd${i}-${idx}`}
                                value={b._id}
                                key={b._id}
                                px={2}
                                colorScheme={"green"}
                              >
                                {b.name}
                              </Radio>
                            ))}
                          </RadioGroup>
                        </Flex>
                      </Box>
                    ))}
                  </Box>
                </TabPanel>

                {/* Address */}
                <TabPanel>
                  {/* Country and City and Area */}
                  <Flex width={"50%"}>
                    {/* Country */}
                    <Box mr={10} width={"25%"}>
                      <Text my={5}>Country:</Text>
                      <Select
                        onChange={(e) => setCountryId(e.target.value)}
                        colorScheme={"green"}
                      >
                        <option>Select a Country</option>
                        {countries?.map((b, i) => (
                          <option key={b._id} value={b._id}>
                            {b.name}
                          </option>
                        ))}
                      </Select>
                    </Box>
                    {/* Cities */}
                    <Box
                      mr={10}
                      width={"25%"}
                      display={
                        countryId?.length > 0 || stay?.country?.length > 1
                          ? ""
                          : "none"
                      }
                    >
                      <Text my={5}>City:</Text>
                      <Select
                        onChange={(e) => setCityId(e.target.value)}
                        colorScheme={"green"}
                      >
                        <option>{"Select a City"}</option>
                        {countryCities?.map((b) => (
                          <option key={b._id} value={b._id}>
                            {b.name}
                          </option>
                        ))}
                      </Select>
                    </Box>
                    {/* Areas */}
                    <Box
                      width={"25%"}
                      display={
                        countryId?.length > 0 || stay?.country?.length > 1
                          ? ""
                          : "none"
                      }
                    >
                      <Text my={5}>Area:</Text>
                      <Select
                        onChange={(e) => setAreaId(e.target.value)}
                        colorScheme={"green"}
                      >
                        <option value={stay?.city?._id}>
                          {"Select a Area"}
                        </option>
                        {countryAreas?.map((b) => (
                          <option key={b._id} value={b._id}>
                            {b.name}
                          </option>
                        ))}
                      </Select>
                    </Box>
                  </Flex>
                  {/* Address */}
                  <Box>
                    <Text my={5}>Address</Text>
                    <HStack
                      alignItems={"start"}
                      justifyContent={"start"}
                      spacing={"10px"}
                    >
                      <Input
                        placeholder="Street:"
                        onChange={(e) =>
                          setAddress({ ...address, street: e.target.value })
                        }
                      />
                      <Input
                        placeholder="Floor :"
                        onChange={(e) =>
                          setAddress({ ...address, floor: e.target.value })
                        }
                      />
                      <Input
                        placeholder="Building:"
                        onChange={(e) =>
                          setAddress({ ...address, building: e.target.value })
                        }
                      />
                    </HStack>
                  </Box>
                  {/* Location */}
                  <HStack spacing={5}>
                    <Box>
                      <Text my={5}>Location Url:</Text>
                      <Input
                        onChange={(e) => setLocationUrl(e.target.value)}
                        type="text"
                        placeholder="location Url"
                      />
                    </Box>
                    {/* Long and Lat */}
                    <Box>
                      <Text my={5}>Location:</Text>
                      <HStack spacing={5}>
                        <Input
                          type="number"
                          placeholder="lat"
                          onChange={(e) =>
                            setLocation({
                              ...location,
                              lat: parseFloat(e.target.value),
                            })
                          }
                        />
                        <Input
                          pl={2}
                          type="number"
                          placeholder="long"
                          onChange={(e) =>
                            setLocation({
                              ...location,
                              long: parseFloat(e.target.value),
                            })
                          }
                        />
                      </HStack>
                    </Box>
                  </HStack>
                  {/* Distance From Town */}
                  <Box>
                    <Text my={5}>Distance from downtown:</Text>
                    <Input
                      type="text"
                      value={distanceFromDownTown}
                      onChange={(e) => setDistanceFromDownTown(e.target.value)}
                      w={"20%"}
                      px={2}
                      placeholder="distance from down Town"
                    />
                  </Box>
                </TabPanel>
                {/* Princing */}
                <TabPanel>
                  {/* Price */}
                  <Box w="50%">
                    <HStack
                      my={10}
                      alignItems={"start"}
                      justifyContent={"start"}
                      spacing={"10px"}
                    >
                      {/* Cleaning Fees  */}
                      <InputGroup w="55%">
                        <InputLeftAddon children="Cleaning Fees:" />
                        <Input
                          type="number"
                          w={"45%"}
                          value={price.cleanFeas}
                          placeholder="Price"
                          onChange={(e) =>
                            setPrice({
                              ...price,
                              cleanFeas: parseInt(e.target.value),
                            })
                          }
                        />
                      </InputGroup>
                      {/* Currency */}
                      <InputGroup>
                        <InputLeftAddon children="currency:" />
                        <Select
                          w={"50%"}
                          rounded="none"
                          value={price.currency}
                          onChange={(e) =>
                            setPrice({ ...price, currency: e.target.value })
                          }
                        >
                          <option>Select a Currency</option>
                          {currencies?.map((curr) => (
                            <option value={curr._id} key={curr._id}>
                              {curr.name}
                            </option>
                          ))}
                        </Select>
                      </InputGroup>
                    </HStack>
                    <HStack alignItems={"end"} my={10} spacing={10}>
                      {/* is It on Sale ? */}
                      <Box>
                        <Checkbox
                          defaultChecked={sale.onSale}
                          value={sale.onSale}
                          onChange={(e) =>
                            setSale({ ...sale, onSale: e.target.checked })
                          }
                        >
                          On Sale
                        </Checkbox>
                      </Box>
                      {/* Sale Percent */}
                      <Box>
                        <InputGroup>
                          <InputLeftAddon children="Sale %" />
                          <Input
                            value={sale.salePercent}
                            pl={2}
                            type="number"
                            placeholder="Sale Percent:"
                            onChange={(e) =>
                              setSale({ ...sale, salePercent: e.target.value })
                            }
                          />
                        </InputGroup>
                      </Box>
                    </HStack>
                    {/* Weekly Price */}
                    <Box>
                      {/* Discounts */}
                      <HStack alignItems={"center"} my={"5"} spacing={5}>
                        {/* WeekDays Price */}
                        <Box>
                          <InputGroup>
                            <InputLeftAddon
                              fontSize={"13px"}
                              children="WeekDays Price:"
                            />
                            <Input
                              type="number"
                              placeholder="WeekDays Price"
                              value={dPrice}
                              onChange={(e) => setDPrice(e.target.value)}
                            />
                          </InputGroup>
                        </Box>
                        {/* Weekend Price */}
                        <Box>
                          <InputGroup>
                            <InputLeftAddon
                              fontSize={"13px"}
                              children="Weekend Price:"
                            />
                            <Input
                              pl={2}
                              type="number"
                              placeholder="Weekend Price"
                              value={wPrice}
                              onChange={(e) => setWPrice(e.target.value)}
                            />
                          </InputGroup>
                        </Box>
                      </HStack>
                    </Box>
                    {/* Discounts */}
                    <Box>
                      <HStack alignItems={"center"} my={"10"} spacing={5}>
                        {/* Weekly Price */}
                        <Box>
                          <InputGroup>
                            <InputLeftAddon
                              fontSize={"13px"}
                              children="Weekly Discount %:"
                            />
                            <Input
                              type="number"
                              placeholder="Weekly Discount %"
                              value={weeklyDiscount}
                              onChange={(e) =>
                                setWeeklyDiscount(e.target.value)
                              }
                            />
                          </InputGroup>
                        </Box>
                        {/* monthly Discount */}
                        <Box>
                          <InputGroup>
                            <InputLeftAddon
                              fontSize={"13px"}
                              children="Monthly Discount %:"
                            />
                            <Input
                              pl={2}
                              type="number"
                              placeholder="Monthly Discount %"
                              value={monthlyDiscount}
                              onChange={(e) =>
                                setMonthlyDiscount(e.target.value)
                              }
                            />
                          </InputGroup>
                        </Box>
                      </HStack>
                    </Box>
                    {/* Monthly */}
                    <Box>
                      {[...Array(numberOfDateRate)].map((_, i) => (
                        <Box key={`numberOfDateRate${i}`}>
                          <HStack alignItems={"center"}>
                            <Box w={"100%"} my={"5"}>
                              <InputGroup>
                                <InputLeftAddon children="Dates Price:" />
                                <Input
                                  w={"100%"}
                                  defaultValue={
                                    stay?.rateAccordingToDate[i]?.rate
                                  }
                                  id={`rate${i}`}
                                  placeholder="Dates Price"
                                />
                              </InputGroup>
                            </Box>
                            <Flex>
                              <Button
                                mr={2}
                                colorScheme={"green"}
                                onClick={() =>
                                  setNumberOfDateRate((prev) => prev + 1)
                                }
                              >
                                <Icon as={BsPlusCircle} />
                              </Button>
                              <Button
                                onClick={() =>
                                  setNumberOfDateRate(numberOfDateRate - 1)
                                }
                                display={numberOfDateRate === 1 ? "none" : ""}
                                colorScheme={"red"}
                              >
                                <Icon as={AiOutlineDelete} />
                              </Button>
                            </Flex>
                          </HStack>
                          <Box w={"20%"}>
                            <InputGroup>
                              <InputLeftAddon>
                                <Icon as={FiCalendar} />
                              </InputLeftAddon>
                              <DatePicker
                                id={i}
                                onChange={(e) => handleChange(e, i, "date")}
                                multiple
                                format="DD/MM/YYYY"
                                style={{
                                  padding: "18px 10px",
                                  width: "24vw",
                                  overflow: "hidden",
                                  borderTopLeftRadius: "0",
                                  borderBottomLeftRadius: "0",
                                }}
                                plugins={[
                                  <DatePanel Toolbar markFocused />,
                                  <Toolbar
                                    position="bottom"
                                    names={{
                                      today: "Select Today",
                                      deselect: "Reset",
                                      close: "Close",
                                    }}
                                  />,
                                ]}
                                // render={<InputIcon  />}
                              />
                            </InputGroup>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                    <Box>
                      {/* Discounts */}
                      {/* <HStack alignItems={"center"} my={"5"} spacing={5}>
                      Discount
                      <Box>
                        <InputGroup>
                          <InputLeftAddon
                            fontSize={"13px"}
                            children="Discount Amout:"
                          />
                          <Input
                            type="number"
                            placeholder="Discount Amount"
                            value={dAmount}
                            onChange={(e) =>
                              setDAmount(e.target.value)
                            }
                          />
                        </InputGroup>
                        </Box>
                        </HStack> */}
                    </Box>
                  </Box>
                </TabPanel>

                {/* Rules */}
                <TabPanel>
                  {/* Rules */}
                  <Box>
                    <Text my={5}>House Rules:</Text>
                    <Flex>
                      {rules?.map((r) => (
                        <Checkbox
                          onChange={() => handleRules(r._id)}
                          px={2}
                          key={r._id}
                          colorScheme={"green"}
                        >
                          {r.name}
                        </Checkbox>
                      ))}
                    </Flex>
                    <Flex mt="10">
                      <InputGroup mr={5} w="25%">
                        <InputLeftAddon children="CheckIn Time" />
                        <Input
                          type="text"
                          value={checkInTime}
                          onChange={(e) => setCheckInTime(e.target.value)}
                          placeholder="CheckIn Time"
                        />
                      </InputGroup>
                      <InputGroup w="25%">
                        <InputLeftAddon children="CheckOut Time" />
                        <Input
                          type="text"
                          value={checkOutTime}
                          onChange={(e) => setCheckOutTime(e.target.value)}
                          placeholder="CheckOut Time"
                        />
                      </InputGroup>
                    </Flex>
                    <Flex mt="10">
                      <InputGroup mr={5} w="25%">
                        <InputLeftAddon children="Minimum Stay" />
                        <Input
                          type="number"
                          value={minNbOfNights}
                          onChange={(e) => setMinNbOfNights(e.target.value)}
                          placeholder="Minimum Stay"
                        />
                      </InputGroup>
                      <InputGroup w="25%">
                        <InputLeftAddon children="Maximum Stay" />
                        <Input
                          type="number"
                          value={maxNbOfNights}
                          onChange={(e) => setMaxNbOfNights(e.target.value)}
                          placeholder="Maximum Stay"
                        />
                      </InputGroup>
                    </Flex>
                    {/* <InputGroup mt="10" w="25%">
                    <InputLeftAddon children="Security Deposite"/>
                    <Input type="text" placeholder="security deposite" />
                    </InputGroup> */}
                    <InputGroup mt="10" w="60%">
                      <InputLeftAddon children="Rules" />
                      <Textarea
                        placeholder="Rules"
                        onChange={(e) => setAdditionalRules(e.target.value)}
                      />
                    </InputGroup>
                  </Box>
                </TabPanel>

                {/* Others */}
                <TabPanel>
                  {/* is New */}
                  <Box>
                    <Checkbox
                      defaultChecked={isNew}
                      value={isNew}
                      onChange={(e) => setIsNew(e.target.checked)}
                    >
                      New
                    </Checkbox>
                  </Box>
                  {/* 360 Image */}

                  <InputGroup mt="5">
                    <InputLeftAddon children="Image360 Url" />
                    <Input
                      type="text"
                      placeholder="Image Url"
                      value={image360url}
                      onChange={(e) => setImage360url(e.target.value)}
                    />
                  </InputGroup>
                  {/* Cancelation Policy */}
                  <Box w="sm">
                    <Text my={5}>Cancelation Policy</Text>
                    <HStack
                      alignItems={"start"}
                      justifyContent={"start"}
                      spacing={"10px"}
                    >
                      <Select
                        w={"50%"}
                        onChange={(e) => setPolicyId(e.target.value)}
                      >
                        <option>Select Policy</option>
                        {cancelations?.map((curr) => (
                          <option value={curr._id} key={curr._id}>
                            {curr.name}
                          </option>
                        ))}
                      </Select>
                    </HStack>
                  </Box>
                  {/* Transport Info */}
                  {[...Array(transPortInfo)].map((_, i) => (
                    <Flex key={`transPortInfoKey${i}`} my={"5"} w="50%">
                      <InputGroup>
                        <InputLeftAddon children="Transport Info:" />
                        <Input type="text" id={`transport${i}`} />
                      </InputGroup>
                      <Flex ml="5">
                        <Button
                          mr={2}
                          colorScheme={"green"}
                          onClick={() => setTransPortInfo((prev) => prev + 1)}
                        >
                          <Icon as={BsPlusCircle} />
                        </Button>
                        <Button
                          onClick={() => setTransPortInfo(transPortInfo - 1)}
                          display={transPortInfo === 1 ? "none" : ""}
                          colorScheme={"red"}
                        >
                          <Icon as={AiOutlineDelete} />
                        </Button>
                      </Flex>
                    </Flex>
                  ))}
                  {/* Other Distances */}
                  {[...Array(otherDistance)].map((_, i) => (
                    <Flex key={`otherDistanceKey${i}`} my={"10"} w="50%">
                      <InputGroup>
                        <InputLeftAddon children="Other Distances:" />
                        <Input type="text" id={`otherDistance${i}`} />
                      </InputGroup>
                      <Flex ml="5">
                        <Button
                          mr={2}
                          colorScheme={"green"}
                          onClick={() => setOtherDistance((prev) => prev + 1)}
                        >
                          <Icon as={BsPlusCircle} />
                        </Button>
                        <Button
                          onClick={() => setOtherDistance(otherDistance - 1)}
                          display={otherDistance === 1 ? "none" : ""}
                          colorScheme={"red"}
                        >
                          <Icon as={AiOutlineDelete} />
                        </Button>
                      </Flex>
                    </Flex>
                  ))}
                  {/* Importnt Info */}
                  {[...Array(importantInfo)].map((_, i) => (
                    <Flex key={`importantInfoKey${i}`} my={"5"} w="50%">
                      <InputGroup>
                        <InputLeftAddon children="Importnt Info:" />
                        <Input type="text" id={`importantInfo${i}`} />
                      </InputGroup>
                      <Flex ml="5">
                        <Button
                          mr={2}
                          colorScheme={"green"}
                          onClick={() => setImportantInfo((prev) => prev + 1)}
                        >
                          <Icon as={BsPlusCircle} />
                        </Button>
                        <Button
                          onClick={() => setImportantInfo(importantInfo - 1)}
                          display={importantInfo === 1 ? "none" : ""}
                          colorScheme={"red"}
                        >
                          <Icon as={AiOutlineDelete} />
                        </Button>
                      </Flex>
                    </Flex>
                  ))}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button onClick={() => handleAddingStay()} colorScheme="green">
              Add Stay
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
export default AddModal;
